import React from "react"

import LandingBio from "../components/landing-bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

const AwardsPage = () => (
  <Layout>
    <SEO title="Awards" keywords={[`amy conroy`, `legal engineer`, `legal tech`, `data scientist`]} />
    <div>
      <h4><a >Most Inspirational Legal Services Data Scientist 2024</a></h4>
      <p> Acquisition International | May 2024 </p>
    </div>
    <div>
      <h4><a href = "https://www.americanbar.org/groups/law_practice/resources/women-of-legal-tech/">American Bar Association Woman of LegalTech 2023</a></h4>
          <p> American Bar Association | September 2023 </p>
  </div>
  

  </Layout>
)

export default AwardsPage
